import http from '../../../utils/services/http'

export const list = (limit, page, filtros, exportar = false) => {
  let url = '/api/habilitacaoArrematanteLote/list?page=' + page + '&limit=' + limit + filtros
  const config = {}
  if (exportar){
    config.responseType = 'blob'
  }
  return http.get(url, config)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const aprovar = (id, extra = '') => {
  let url = `/api/habilitacaoArrematanteLote/${id}/aprovar?${extra}`
  return http.put(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const reprovar = (id, extra = '') => {
  let url = `/api/habilitacaoArrematanteLote/${id}/reprovar?${extra}`
  return http.put(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const excluir = (id) => {
  let url = `/api/habilitacaoArrematanteLote/${id}/excluir`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const habilitar = (arrematante, leilao, lote, direitoPreferencia) => {
  let url = `/api/habilitarArrematanteLote/${arrematante}/${leilao}`
  return http.put(url, {
    lote,
    direitoPreferencia
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
