<template>
  <ul>
    <menu-options-item close label="Aprovar somente para lance" @click="$emit('aprovar')" icon="check" label-class="text-positive" />
    <menu-options-item close label="Aprovar + Direito de Preferência" @click="$emit('aprovarDp')" icon="check-double" label-class="text-purple" />
    <menu-options-item close label="Bloquear lance" label-class="text-negative" @click="$emit('reprovar')" icon="lock" />
    <menu-options-item close label="Excluir registro" label-class="text-black" @click="$emit('excluir')" icon="times" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
export default {
  name: 'lote-list-menu',
  props: ['habilitacao'],
  components: {MenuOptionsItem}
}
</script>
